.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #2c3e50;
  color: #ecf0f1;
  font-family: 'Courier New', Courier, monospace;
  padding: 20px;
  box-sizing: border-box;
}

.terminal {
  background: #34495e;
  border: 1px solid #7f8c8d;
  border-radius: 10px;
  width: 100%;
  max-width: 800px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  height: 80vh;
  overflow: hidden;
}

.message-container {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 10px;
  white-space: pre-wrap;
}

.message {
  margin: 10px 0;
  word-wrap: break-word;
}

.message.user {
  color: #3498db;
}

.message.assistant {
  color: #e74c3c;
}

form {
  display: flex;
}

.terminal-input {
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  width: 100%;
  resize: none;
  overflow-y: auto; /* Enable vertical scrolling */
  min-height: 50px; /* Initial height for the textarea */
  max-height: calc(50vh - 30px); /* Maximum height to half the message container */
  box-sizing: border-box; /* Ensures padding is included in height calculation */
}

.terminal-input:focus {
  outline: none;
}
